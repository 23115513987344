import React, { Component } from 'react'
import { Link } from "react-router-dom"
import { withTranslation } from 'react-i18next'
import InsButton from '../../../components/InsButton/InsButton';
import InsHeaderConnect from '../../../components/InsHeaderConnect/InsHeaderConnect';
import InsInput from '../../../components/InsInput/InsInput';
import Validate from '../../../ressources/validation';
import images from '../../../ressources/images';
import Modal from 'react-responsive-modal';
import ModalError from '../../../components/ModalError/ModalError';
import { forgotPassword } from '../../../api/account';

import './ForgetPassword.scss'

export class ForgetPassword extends Component {
  constructor(props) {
    super(props)
    this.state = {
        email: '',
        emailError: '',
        open: false,
        sendEmail: true
    }
  }

  /**
   * Récupére la valeur du champ email
   */
  handleChange = input => e => {
      this.setState({[input] : e.target.value}) 
  }

   /**
     * Vailde le formulaire de l'étape
     */
    _validateFormStep = () => {
      const { email } = this.state
      return (
      !Validate('email', email)
      )
  }

  /**
  * Valide le champ email
  */
  _validateEmail = () => {        
      const error = Validate('email', this.state.email);
      this.setState({
          emailError: error
      })
      return !error;
  }

  /**
   * Ouvre la modal de confirmation d'envoie de mail
   */
  openModal = () => {
    this.setState({ open: true });
  };

  /**
   * Ferme la modal de confirmation
   */
  closeModal = () => {
    this.setState({ open: false });
  };

  /**
   * Envoie du mail de réinitialisation de mot de passe
   */
  sendEmail = () => {
    const { email } = this.state
    forgotPassword(email).then(() => {
      this.openModal()
    }, (error) => {
      this.setState({sendEmail: false})
      this.openModal()
    })
  }

  continue = e => {
    let {emailError, email } = this.state
     email !== '' && emailError === null ? this.sendEmail() : e.preventDefault()
  }
  render() {
    const { open, sendEmail } = this.state    
    const { t } = this.props
    return (
      <div>
        <InsHeaderConnect title={t("connect:forget_password")} />
        <div className="default-container col-flex">
          <div className='forget_text bold-label centered mt150'>
            {t('connect:forget_password_text')}
          </div>
          <img src={images.mail} className='mail_img mt40 mb30' alt=''/>
          <InsInput 
                      onChange={this.handleChange('email')}
                      onBlur={this._validateEmail }
                      type="email" name="forget_mail" 
                      placeholder={t("connect:email_address")} 
                      error={this.state.emailError}
          />
          <div className="bottom-container">
            <InsButton 
                text={t("common:validate")}
                onClick={this.continue}
                disabled={!this._validateFormStep()}
            />
          </div>
          {sendEmail ?
            <Modal open={open} onClose={this.closeModal} center >
              <h2 className="modal-title">{t('connect:email_send')}</h2>
              <div className="modal-content ph10">{sendEmail ? t('connect:forget_password_mail') : 'error'}</div>
              <Link to='/'>
                <InsButton 
                  text={t("connect:return_home")}
                />
              </Link>
            </Modal>
            :
            <ModalError
              open={open}
              onClose={this.closeModal}
              title={t("error:modalError.title.default")}
              text={t("error:modalError.text.default")}
              btnText={t("error:modalError.btnText.default")}
              center 
            />
          }
          </div>
      </div>
    )
  }
}

export default withTranslation()(ForgetPassword)
