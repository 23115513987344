import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import InsButton from "../../../components/InsButton/InsButton";

import "./Intro.scss";
import WeAreLL from "../../../components/WeAreLL/WeAreLL";
import { splashscreen } from "../../../api/splashscreen";
import images from "../../../ressources/images";

class Intro extends Component {
  constructor(props) {
    super(props);
    this.state = {
      api: false,
      backgroundImage: null,
      video: null,
      videoMuted: true,
    };
  }

  _redirectLogin = () => {
    this.props.history.push(`/connect/login`);
  };

  componentDidMount() {
    splashscreen().then(
      (data) => {
        this.setState({
          api: true,
          backgroundImage: data["splashscreen"][0]
            ? process.env.REACT_APP_URL +
            "/backend/uploads/splashscreen/picture/" +
            data["splashscreen"][0].picture
            : null,
          video: data["launchVideo"][0]
            ? process.env.REACT_APP_URL +
              "/backend/uploads/launchVideo/video/" +
              data["launchVideo"][0].video
            : null,
        });
      },
      () => {
        this.setState({
          api: true,
        });
      }
    );
  }

  videoEnded = () => {
    this.setState({ video: null });
  };

  unmuteVideo = () => {
    setTimeout(() => {
      this.setState({ videoMuted: !this.state.videoMuted });
    }, 500);
  };

  render() {
    const { t } = this.props;
    const { api, backgroundImage, video, videoMuted } = this.state;
    const launchVideo = video != null
    const startVideo = this.props.location.search.split('=')[1]
    return (
      <>
        {api && (
          <div
            className="intro-bg"
            style={
              backgroundImage && { backgroundImage: `url(${backgroundImage})` }
            }
          >
            <div className="intro-content">
              <WeAreLL />
              <div className="intro-text-info">
                Site de e-learning pour conseillères de beauté uniquement
              </div>
              <p>
                <InsButton
                    text={t("connect:connect")}
                    type="white"
                    link="/connect/login"
                />
              </p>
              <p>
                <InsButton
                    text={t("connect:create_account")}
                    type="outline"
                    link="/connect/create"
                />
              </p>
            </div>
            {launchVideo && startVideo !== 'no' ? (
              <div className="video_promo">
                <div className="close_video" onClick={this.videoEnded}>
                  <img src={images.closeCross} alt="" />
                </div>
                <video
                  src={video}
                  playsInline
                  autoPlay
                  muted={videoMuted}
                  onEnded={this.videoEnded}
                />
                <div className="sound" onClick={this.unmuteVideo}>
                  <img src={videoMuted ? images.mute : images.unmute} alt=""/>
                </div>
              </div>
            ) : null}
          </div>
        )}
      </>
    );
  }
}

export default withTranslation()(Intro);
