import React from 'react';
import { withTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';

function ShopListItem(props) {
  const {cart, variations, limited, name, brand, image, points, userPoints, stock, t, outlet, gold} = props
    let total_stock = stock;
    variations.forEach(variation => total_stock += variation.stock);
  return (
      <div className={gold ? "product_item gold" : "product_item"}>
        {/* PRODUCT PRICE */}
        {total_stock > 0 &&
          <div className={userPoints - cart.total >= points ? "product_price" : "product_price disable"}>{points}</div>
        }

        {/* PRODUCT FLAGS */}
        <div className="product_flags">

          {total_stock === 0 ?
            /* UNAVAIBLE PRODUCT */
            <div className="unavaible">
              {t('shop:unavaible')}
            </div>
          :
          total_stock <= 10 ?
            /* DERNIERE PIECES */
            <div className="last_pieces">
              {t('shop:last_pieces')}
            </div>
          :
          null
          }

          {limited ?
            /* EDITION LIMITEE */
            <div className="limited_edition">
              {t('shop:limited_edition')}
            </div>
            :
            null
          }

            {gold ?
                /* EDITION LIMITEE */
                <div className="gold">
                    gold
                </div>
                :
                null
            }

          {outlet ?
              /* OUTLET */
              <div className="outlet">
                  {t('shop:outlet')}
              </div>
              :
              null
          }
        </div>

        {/* PRODUCT IMAGE */}
        <div className="product_img">
          <LazyLoadImage src={process.env.REACT_APP_URL + image} alt=""/>

          {variations != null ?
            /* PRODUCT DECLINATION */
            <div className="product_variations">
              {variations.map((variation, index) => (
                index < 4
                ?
                  variation.color ?
                    <div className="variation variation_color" key={index} style={{backgroundColor: variation.color}}></div>
                  :
                    <div className="variation variation_capacity" key={index}>{variation.capacity} </div>
                :
                ''
              ))}
            </div>

            :
            null
          }
        </div>

        {/* PRODUCT NAME */}
        <div className={total_stock === 0 ? "product_infos stock_out" : "product_infos"}>
          <div className="brand">{brand}</div>
          <div className="name">{name}</div>
        </div>

      </div>
  )
}

export default withTranslation()(ShopListItem)
