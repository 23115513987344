const images = {
    intro_mobile: require('./images/intro_mobile.jpg'),
    mail: require('./images/mail.svg'),
    logo: require('./images/logo.png'),
    like: require('./images/like.svg'),
    good: require('./images/good.svg'),
    error: require('./images/error.svg'),
    indicationLike: require('./images/indicationLike.svg'),
    indicationDislike: require('./images/indicationDislike.svg'),
    closeCross: require('./images/closeCross.svg'),
    contact: require('./images/contact.svg'),
    truefalse: require('./images/truefalse.gif'),
    wall_waiting: require('./images/wall_waiting.png'),
    shop_waiting: require('./images/shop_waiting.png'),
    we_are_lol_white_svg: require('./images/lol_logo_white.svg'),
    we_are_lol_white: require('./images/lol_logo_white.png'),
    we_are_lol_logo_event: require('./images/lol_logo_event.png'),
    we_are_lol_black_svg: require('./images/lol_logo_black.svg'),
    we_are_lol_black: require('./images/lol_logo_black.png'),
    icon_user: require('./images/icon-user.jpg'),
    womens_sponroship: require('./images/parrainage/womens_sponsorship.png'),
    icon_sponsorship: require('./images/parrainage/icon_sponsorship.png'),
    maintenance: require('./images/maintenance.png'),
    mute: require('./images/mute.svg'),
    unmute: require('./images/volume.svg'),
    picto_formation: require('./images/picto_formation.png'),
    location: require('./images/location.png'),
    notifications: require('./images/notifications.svg'),
    notifications_or: require('./images/notifications_or.svg'),

    //images profile
    orders: require('./images/profil/orders.svg'),


    //img brands
    arrow_left_white: require('./images/brands/arrow-left-white.svg'),
    arrow_left_black: require('./images/brands/arrow-left-black.svg'),
    love_icon_white: require('./images/brands/love-icon.svg'),
    article_icon_black: require('./images/brands/article-icon-black.svg'),
    article_icon_grey: require('./images/brands/article-icon-grey.svg'),
    formation_icon_black: require('./images/brands/formation-icon-black.svg'),
    formation_icon_grey: require('./images/brands/formation-icon-grey.svg'),
    produit_icon_black: require('./images/brands/produit-icon-black.svg'),
    produit_icon_grey: require('./images/brands/produit-icon-grey.svg'),
    video_icon_black: require('./images/brands/video-icon-black.svg'),
    video_icon_grey: require('./images/brands/video-icon-grey.svg'),
    tools_icon_grey: require('./images/brands/tools-icon-grey.svg'),
    tools_icon_black: require('./images/brands/tools-icon-black.svg'),
    bg_none: require('./images/brands/bg-none.png'),
    product_brand_1: require ('./images/brands/test-product-by-brand-1.png'),
    product_brand_2: require ('./images/brands/test-product-by-brand-2.png'),

    //images wall
    wall_icon_list: require('./images/wall/wallList-icon.png'),
    wall_icon_list_selected: require('./images/wall/wallList-icon-selected.png'),
    wall_icon_filter: require('./images/wall/wall-filter.png'),
    icon_video_white: require('./images/wall/icon_video_white.svg'),
    icon_play_white: require('./images/wall/icon_play_white.svg'),
    icon_multiple: require('./images/wall/icon_multiple.svg'),
    checked_black: require('./images/wall/checked_black.png'),

    //images shop
    cart_ok: require('./images/shop/cart_ok.svg'),
    order_confirm: require('./images/shop/order_confirm.svg'),

    // img formation
    bg_formation_VIRTUAL_COURSE: require('./images/formation/VIRTUAL_COURSE.jpg'),
    bg_formation_VIRTUAL_COURSE_small: require('./images/formation/VIRTUAL_COURSE_SMALL.jpg'),
    bg_formation_PHYSICAL_COURSE: require('./images/formation/PHYSICAL_COURSE.jpg'),
    bg_formation_PHYSICAL_COURSE_small: require('./images/formation/PHYSICAL_COURSE_SMALL.jpg'),
    bg_formation_TRAINING_EVENING: require('./images/formation/TRAINING_EVENING.jpg'),
    bg_formation_TRAINING_EVENING_small: require('./images/formation/TRAINING_EVENING_SMALL.jpg'),
    bg_formation_DIGITAL_COURSE: require('./images/formation/DIGITAL_COURSE.jpg'),
    bg_formation_DIGITAL_COURSE_small: require('./images/formation/DIGITAL_COURSE_SMALL.jpg'),
    bg_formation_DIGITAL_EVENT: require('./images/formation/DIGITAL_EVENT.jpg'),
    bg_formation_DIGITAL_EVENT_small: require('./images/formation/DIGITAL_EVENT_SMALL.jpg'),
    bg_formation_STORE_COURSE: require('./images/formation/STORE_COURSE.jpg'),

}

export default images;
