import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import "./ItemHp.scss";
import LazyLoadBackground from "../LazyLoadBackground/LazyLoadBackground";

export class ItemHp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      size: this.props.size,
    };
  }
  static propTypes = {
    brand: PropTypes.string,
    background: PropTypes.string.isRequired,
    logo: PropTypes.string,
    type: PropTypes.string,
    size: PropTypes.number,
    title: PropTypes.string.isRequired,
  };

  static defaultPropTypes = {
    size: 0,
  };

  render() {
    const {
      brand,
      brandId,
      youtubeId,
      background,
      logo,
      type,
      title,
      size,
      id,
    } = this.props;

    const logoUrl = `${process.env.REACT_APP_URL}${logo}`;
    const loaded = `${process.env.REACT_APP_URL}${background}`;

    switch (type) {
      case "news":
        return (
          <Link
            to={{
              pathname: "/news/" + id,
              state: { transitionName: "zoom_in" },
            }}
          >
            <LazyLoadBackground
              height={"calc(14rem + (" + size + " * 16rem))"}
              src={loaded}
              className="item_hp"
            >
              {logo ? (
                <img className="item_logo" src={logoUrl} alt="" />
              ) : (
                <div className="item_brand">{brand}</div>
              )}
              <div className="item_text">
                <div className="item_type">{type}</div>
                <div className="item_title">{title}</div>
              </div>
            </LazyLoadBackground>
          </Link>
        );

      case "formation":
        return (
          <Link
            to={{
              pathname: "/formations/" + id,
              state: { transitionName: "slide_in" },
            }}
          >
            <LazyLoadBackground
              height={"calc(14rem + (" + size + " * 16rem))"}
              src={loaded}
              className="item_hp"
            >
              {logo ? (
                <img className="item_logo" src={logoUrl} alt="" />
              ) : (
                <div className="item_brand">{brand}</div>
              )}
              <div className="item_text">
                <div className="item_type">{type}</div>
                <div className="item_title">{title}</div>
              </div>
            </LazyLoadBackground>
          </Link>
        );

      case "video":
        return (
          <Link
            to={{
              pathname: "/brands/videos/" + brandId + "/watch=" + youtubeId,
              state: {
                transitionName: "slide_in",
                fromHomepage: "true",
                id: id,
              },
            }}
          >
            <LazyLoadBackground
              height={"calc(14rem + (" + size + " * 16rem))"}
              src={loaded}
              className="item_hp"
            >
              {logo ? (
                <img className="item_logo" src={logoUrl} alt="" />
              ) : (
                <div className="item_brand">{brand}</div>
              )}
              <div className="item_text">
                <div className="item_type">{type}</div>
                <div className="item_title">{title}</div>
              </div>
            </LazyLoadBackground>
          </Link>
        );
      default:
        return null;
    }
  }
}

export default ItemHp;
