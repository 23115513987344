import './BrandVideosItem.scss'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"

export class BrandVideosItem extends Component {
  static propTypes = {
    VideoId: PropTypes.number,
    VideoPicture: PropTypes.string,
    VideoTitle: PropTypes.string,
    VideoSubtitle: PropTypes.string,
    YoutubeId: PropTypes.string,
  }

  render() {
    let {brandId, YoutubeId, VideoPicture, VideoId, VideoTitle, VideoSubtitle} = this.props

    let style = {
      backgroundImage: 'url('+process.env.REACT_APP_URL+VideoPicture +')'
    }

    return (
      <Link 
      className="video_link"
      to={{
        pathname: '/brands/videos/'+brandId+'/watch='+YoutubeId,
        state: { 
          transitionName: "slide_in",
          id: VideoId
        }
      }} 
      >
        <div className="video_item" style={style}>
          <div className="video_container">
            <div className="video_title">
              {VideoTitle}
            </div>
            <div className="video_subtitle">
              {VideoSubtitle}
            </div>
          </div>
        </div>
      </Link>
    )
  }
}

export default BrandVideosItem
